import React from 'react';
import {Link} from 'react-router-dom';
import { GiDolphin } from "react-icons/gi";
import {FaTiktok, FaFacebookSquare,FaYoutube, FaTwitterSquare, FaInstagram} from 'react-icons/fa';
import {TfiYoutube} from 'react-icons/ti'
import { IoIosMusicalNote } from "react-icons/io";

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("./images/7.jpeg")`,backgroundAttachment:'fixed',backgroundSize:'cover'
    }}>
      <div className='footer-content'>

 



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><GiDolphin></GiDolphin></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='profile' onClick={scrollFunc}> <p><GiDolphin></GiDolphin></p> <p className='footer-a'>Company Profile</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='services' onClick={scrollFunc}> <p><GiDolphin></GiDolphin></p> <p className='footer-a'>Our Services</p></Link> 
                        </div>
                         <div className='footer-link'>
                              <Link to='clients' onClick={scrollFunc}> <p><GiDolphin></GiDolphin></p> <p className='footer-a'>Our Clients</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='dolphin-foundation' onClick={scrollFunc}> <p><GiDolphin></GiDolphin></p> <p className='footer-a'>Dolphine Foundation</p></Link> 
                        </div> 
                        <div className='footer-link'>
                              <Link to='events' onClick={scrollFunc}> <p><GiDolphin></GiDolphin></p> <p className='footer-a'>Events</p></Link> 
                        </div> 
                        
                                       
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Our Address</h3>
                      <p>
                      Next Media Park, Plot
13, Summit View Road,
Naguru, Kampala City - Uganda

                        </p>
                        
                     

                    </div>

                   <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                 +256 782 349002<br></br>
+256 703 700277
                      
                      
                      </p>

                    </div>

                    
                      

             </div>


                   <div className='footer-inner-content'>


                    

                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                  
                   <p>
                  
                   info@dolphineuganda.com<br></br>
                   dolphineentertainmentuganda@gmail.com
                    </p>
                    
                      
                      </div>
                      <div className='footer-email'>
                             <Link to='inquiries' className='inquiries-link' onClick={scrollFunc}>Inquiries</Link>
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                            
                                <a href='https://www.facebook.com/SoloEventsLtd/' target="_blank" rel='noreferrer'> <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span></a>
                          
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Dolphine Entertainment Uganda Limited | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer