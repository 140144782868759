import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';

const Team = () => {

useEffect(()=>{
document.title = 'Gallery | Amazing Grace Acapella';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container content-container-team'>
<h2 className="home-h2 home-h2-a">Gallery</h2>
                    <div>
                        <VisionCarousel></VisionCarousel>
                    </div>
            
        </div>
        </section> 
    </>
  )
}

export default Team