import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
const Team = () => {

useEffect(()=>{
document.title = 'Dolphin Foundation | Dolphine Entertainment Uganda Limited';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container foundation-content'>
<h2 className="home-h2 home-h2-a">Dolphin Foundation</h2>
 <div>
                        <VisionCarousel></VisionCarousel>
                                           
                    </div>
 <h3 className='foundation-h3'>Helping Children with Autism Spectrum Disorder (ASD)</h3>
                <p className='home-p'>Since its incorporation, Dolphine Entertainment has been supporting children in Uganda
that suffer from Autism Spectrum Disorder (ASD), commonly known as Autism.
Autism is a developmental disability caused by differences in the brain. Children with ASD
often have problems with social communication and interaction, and restricted or
repetitive behaviors or interests. So, this means that they cannot learn, play or even pay
attention like any other child. Their ways of learning are often different from others.
Dolphine Entertainment dedicates a percentage of its revenue to Dolphin Foundation that
is dedicated to not just rehabilitating them but also helping them to discover and learn
their potential, love it, and create self-sustainability over time.
</p>
            <p className='home-p'>The foundation relies on revenue from Dolphine Entertainment to keep afloat, in addition
to the generous support from various donors, volunteers, and well-wishers. So, as you
engage in business with Dolphine Entertainment, you are also supporting a multitude of
children under the Dolphin Foundation.</p>
   
        </div>
        </section> 
    </>
  )
}

export default Team