import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Promotion Strategy | Dolphine Entertainment Uganda Limited';

},[]);


  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/41.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'10rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Promotion Strategy</h2>
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container services-container-a'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Our Promotion Strategy Solutions</h3>                    
{/* <ul>
    <li><strong>Client:</strong>  Mr. David Katiisa</li> 
<li><strong>Location:</strong> Kitifumba</li>
<li><strong>Period:</strong> March 2019 - December 2021</li>
<li><strong>Value: </strong>Ugx 850,000,000</li>
</ul> */}
 <p>To us, your promotion strategy is everything! Our goal is to make sure that
your brand reaches the people
who matter most so that they
can find you easily and keep
coming back for more.
</p>
                  {/* <Link to='#' className='home-link'>See Details</Link> */}
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>



          
            
           </div>
           <div className='content-container foundation-content foundation-content-a'>

 <h3 className='foundation-h3'>Our Service</h3>
                <p className='home-p home-p-b'>Dolphine Entertainment has
worked with various brands and
helped them to get noticed
through promoting their
products and services.


</p>

<p className='home-p home-p-b'>Dolphine understands that a
successful marketing campaign
is more than just having a great
product—it's about finding the
right audience, building trust
with that audience, and then
teaching that audience about
your product or service. That is
exactly what we do here at
Dolphine. We enter the field and
maximize your sales potential.

</p>
         
   
        </div>

    </section>
     {/* <section className='section-cover team-cover'>
        
          
        </section>  */}
    </>
  )
}

export default Services