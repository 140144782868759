import Carousel from 'react-bootstrap/Carousel';

function WaterSupply() {
  return (
    <Carousel  indicators={false} className="concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/136.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/137.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/138.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/139.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/140.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/141.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/142.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/143.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/144.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/145.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>

       

    </Carousel>
  );
}

export default WaterSupply;