import Carousel from 'react-bootstrap/Carousel';

function Hardware() {
  return (
    <Carousel indicators={false} className='concrete-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/146.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/147.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/148.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/149.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/150.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>


      
    </Carousel>
  );
}

export default Hardware;