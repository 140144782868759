import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='concrete-carousel'>
     
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/87.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/88.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/89.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/90.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/91.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/92.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/93.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/94.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/95.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/96.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      
    </Carousel>
  );
}

export default Residential1;