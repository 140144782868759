import React, {useEffect} from 'react'
import VisionCarousel from '../components/Mission Carousel';
import { Link } from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}
const Team = () => {

useEffect(()=>{
document.title = 'Events | Dolphine Entertainment Uganda Limited';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container foundation-content'>
<h2 className="home-h2 home-h2-a">Events</h2>
<div>
                        <VisionCarousel></VisionCarousel>
                                           
                    </div>
                    
                    <h3 className='foundation-h3'>Love At The Sky - 14<sup>th</sup>/02/2024</h3>
                    <Link to='/love-at-the-sky' className='events-link events-link-a' onClick={scrollFunc}>See Details</Link>
 {/* <h3 className='foundation-h3'>Press Conference for Love At The Sky</h3> */}
                        
 {/* <p className='home-p home-p-a'>The main sponsor for this event is Gabriel Bar Collection. Other sponsors include Sanyuka TV, Talent Africa (doing the production), NBS TV and Afro Mobile. </p> */}
                                     {/* <div className='events-image-cover'>
                        <VisionCarousel></VisionCarousel>
                        <img src='./images/40.jpg' className='events-image' alt='image'></img>
                                           
                    </div> */}
 
   
        </div>
        </section> 
    </>
  )
}

export default Team