import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Donate | Amazing Grace Acapella';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container content-container-team'>
<h2 className="home-h2 home-h2-a">Donate</h2>
<p className='home-p home-p-a'><strong><em>Donate to us through any of the mobile money numbers below:</em></strong></p>
            <p className='home-p home-p-a'>+256789 415352<br></br> (Andrew Bakkabulindi) <br></br><br></br>  +256705 471122 <br></br>(Aggrey Samuel Kyalirizo) </p>
            
        </div>
        </section> 
    </>
  )
}

export default Team