import React, {useEffect} from 'react';



const Services = () => {

useEffect(()=>{
document.title = 'Company Profile | Dolphine Entertainment Uganda Limited';

},[]);


  return (
  <section className='container'>
      <iframe
        src='./docs/Dolphine Company Profile.pdf'
        className='profile'
      />


  </section>
   
  
  )
}

export default Services