import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Digital Marketing | Dolphine Entertainment Uganda Limited';

},[]);


  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/2.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'10rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Digital Marketing</h2>
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container services-container-a'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Our Digital Marketing Solutions</h3>                    
{/* <ul>
    <li><strong>Client:</strong>  Mr. David Katiisa</li> 
<li><strong>Location:</strong> Kitifumba</li>
<li><strong>Period:</strong> March 2019 - December 2021</li>
<li><strong>Value: </strong>Ugx 850,000,000</li>
</ul> */}
 <p>We know what it takes to get ahead in business. That's why we've built our reputation
on delivering results above and beyond industry standards. We know how to bring your
brand closer to the hearts of your customers by making sure that your brand stands out
in the digital world amidst tight competition and still make the public want more of it!
</p>
                  {/* <Link to='#' className='home-link'>See Details</Link> */}
                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>



          
            
           </div>
           <div className='content-container foundation-content foundation-content-a'>

 <h3 className='foundation-h3'>Our Service</h3>
                <p className='home-p home-p-b'>We have helped so many brands to get better market positioning through digital marketing. We are
experts at planning and executing effective social media campaigns. Our team of experts works with
you every step of the way, from idea generation through implementation. We work closely with
various social media influencers and digital platforms of various celebrities, public figures and
media houses to bring value to your brand.
</p>
         
   
        </div>

    </section>
     {/* <section className='section-cover team-cover'>
        
          
        </section>  */}
    </>
  )
}

export default Services