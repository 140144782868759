import Carousel from 'react-bootstrap/Carousel';

function HealthCenter() {
  return (
    <Carousel  indicators={false} className="concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/117.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/118.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/119.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/120.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/121.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/122.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/123.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/124.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/125.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/126.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>

    </Carousel>
  );
}

export default HealthCenter;