import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Partners | Amazing Grace Acapella';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container content-container-team'>
<h2 className="home-h2 home-h2-a">Our Partners</h2>
            <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/18.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/19.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/20.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/21.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/22.jpeg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/23.jpeg' alt='client' className='client-image'></img>
           </article>

          
          </div> 
            
        </div>
        </section> 
    </>
  )
}

export default Team