import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/98.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/99.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/100.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/101.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/102.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/103.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      
    </Carousel>
  );
}

export default PowerCarousel;