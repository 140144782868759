import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Services | RAK ENGINEERING LTD';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Building Construction</h3>
                <p>We have served as Engineering consultants and contractors on almost every type of building project including, domestic housing, schools, hotels, sports centers, offices, factories, hospitals and health centers.  </p>
                  <p>RAK ENGINEERING LTD has highly qualified and experienced specialists in building construction, building design, construction management as well as construction supervision and maintenance work.  </p>
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <BuildingsCarousel></BuildingsCarousel>
                  </article>     
            </div>
           </div>

    </section>
  )
}

export default Services