import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Clients | Dolphine Entertainment Uganda Limited';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
          {/* <p className='clients-para'><strong>RAK ENGINEERING LTD</strong> has in the recent past worked on a number of projects and has registered commendable success among clients that include:</p> */}
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/8.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/9.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/10.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/11.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/12.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/13.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/14.jpg' alt='client' className='client-image'></img>
           </article>
          
          
          </div> 
    </section>
  )
}

export default Clients