import React, {useEffect} from 'react';
import {BsBarChartFill} from 'react-icons/bs';
import { FaPlay} from "react-icons/fa";
import { GoMegaphone } from "react-icons/go";
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Our Services | Dolphine Entertainment Uganda Limited';

},[]);


  return (
<section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2'>We understand the right solutions for your business</h2>
    {/* <div className='line1'> 
    <div className='line2'></div>
    </div> */}
   
    <div className='home-services-container '>
   
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GoMegaphone /></span>
          </div>
          <h3 className='home-service-title'>Digital Marketing</h3>
          <img src='./images/7.jpeg' className='service-image'></img>
          <p>
            We provide our clients
with creative solutions
for social media
campaigns, video
production, brand
positioning with social
media influencers,
celebrity & public
figure brand mentions
and many more.
            </p>
 <Link to='/digital-marketing' className='read-more-btn' onClick={scrollFunc}>Read More</Link>
        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaPlay /></span>
          </div>
          <h3 className='home-service-title'>Media Booking</h3>
          <img src='./images/41.jpg' className='service-image'></img>
          <p>
            We help brands with
media booking such as
for TV & Radio adverts,
press conferences, talk
shows, exclusive
interviews and many
more at the best rates.
We also plan & execute
media strategies.
            </p>
            <Link to='/media-booking' className='read-more-btn' onClick={scrollFunc}>Read More</Link>

        </div>
       
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><BsBarChartFill></BsBarChartFill></span>
          </div>
          <h3 className='home-service-title'>Promotion Strategy</h3>
           <img src='./images/43.jpeg' className='service-image'></img>
          <p>
           Everything we do is
driven by our purpose
to change the way
world experiences
Everything we do is
driven by our purpose
to change.
            </p>
             <Link to='/promotion-strategy' className='read-more-btn' onClick={scrollFunc}>Read More</Link>
        </div>



    </div>
       


        </div>


    </div>



  </section>
  )
}

export default Services