import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/104.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/105.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/106.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/107.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
      
      
      
    </Carousel>
  );
}

export default PowerCarousel;